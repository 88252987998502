import whoIAmImage from "./images/fln-tech-high-resolution-logo-black-on-transparent-background.png";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import StarIcon from "@mui/icons-material/Star";
import TuneIcon from "@mui/icons-material/Tune";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import RttIcon from "@mui/icons-material/Rtt";
import CloudIcon from "@mui/icons-material/Cloud";

type SubItem = {
  title: string;
  paragraphs: string[];
  icon?: any;
  price?: number;
  extra?: string;
};
type Page = {
  name: string;
  title: string;
  backgroundColor: string;
  textColor: string;
  paragraphs: string[];
  paragraphImage?: string;
  items?: SubItem[];
  conclusion?: string;
  action?: string;
  subSection?: Page[];
};

export const landingPageActionButtonMessage =
  "Olá! Gostaria de mais informações sobre os pacotes da FLN Tech. Obrigado!";
export const landingPageActionButtonNumber = "5548996198272";
export const cellphone = "48996198272";

export const pages: Page[] = [
  {
    name: "Sobre",
    title: "Quem somos",
    backgroundColor: "#FFFFFF",
    textColor: "#d8ae9c",
    paragraphs: [
      "FLN Tech",
      "Surgimos com a necessidade de resolver problemas de tecnologia de forma simples e eficiente. Com o objetivo de oferecer soluções para empresas e pessoas, a FLN Tech é uma empresa de tecnologia que oferece serviços de desenvolvimento de software com auxílio de plataformas de Inteligência Artificial.",
      "Nossa equipe é formada por profissionais com experiência em desenvolvimento de software e contamos com o apoio de revisores e designers para fazer um trabalho completo com a sua marca.",
      "Veja nossas soluções abaixo:",
    ],
    paragraphImage: whoIAmImage,
  },
  {
    name: "Soluções",
    title: "Soluções",
    backgroundColor: "#F4F4F6",
    textColor: "#d8ae9c",
    paragraphs: [],
    items: [
      {
        title: "FLN Express",
        paragraphs: [
          "Site básico.",
          "Modelo único (esse site é um exemplo).",
          "Entrega em até 3 dias.",
          "Conteúdo básico de acordo com a área de atuação.",
          "Branding disponibilizado pelo cliente.",
        ],
        icon: ElectricBoltIcon,
        price: 490.9,
      },
      {
        title: "FLN Essentials",
        paragraphs: [
          "Site completo com sistema de páginas.",
          "Três modelos disponíveis.",
          "Entrega em até 7 dias.",
          "Conteúdo básico de acordo com a área de atuação.",
          "Pacote FLN Branding completo.",
        ],
        icon: TipsAndUpdatesIcon,
        price: 1490.9,
      },
      {
        title: "FLN Complete",
        paragraphs: [
          "Site completo com sistema de páginas.",
          "Três modelos disponíveis.",
          "Entrega em até 10 dias.",
          "Pacote FLN Content completo.",
          "Pacote FLN Branding completo.",
        ],
        icon: StarIcon,
        price: 2490.9,
      },
      {
        title: "FLN Custom",
        paragraphs: [
          "Não achou a solução que precisa? Entre em contato conosco e vamos conversar sobre o seu projeto.",
        ],

        icon: TuneIcon,
        extra: "Consulte",
      },
    ],
  },
  {
    name: "Soluções Adicionais",
    title: "",
    backgroundColor: "#F4F4F6",
    textColor: "#d8ae9c",
    paragraphs: [],
    subSection: [
      {
        name: "Oferecemos esses serviços adicionais que podem ser contratados junto com as soluções acima ou separadamente:",
        title:
          "Oferecemos serviços adicionais que podem ser contratados junto com as soluções acima ou separadamente:",
        paragraphs: [],
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        items: [
          {
            title: "FLN Branding",
            paragraphs: [
              "Pacote de marca exclusivo.",
              "Paleta de cores e tipografia.",
              "Logos em alta e baixa resolução.",
              "Arquivos em formato vetor para material digital ou impressão.",
              "Entrega em até 3 dias.",
              "Marca gerada por IA com acompanhamento de designer e com direitos de uso garantidos.",
            ],
            icon: BrandingWatermarkIcon,
            price: 890.9,
          },
          {
            title: "FLN Content",
            paragraphs: [
              "Pacote de conteúdo exclusivo.",
              "Conteúdo específico para área de atuação.",
              "Pronto para uso no site.",
              "Pronto para uso em material de publicitário.",
              "Entrega em até 4 dias.",
              "Conteúdo gerado por IA com acompanhamento de um revisor.",
            ],
            icon: RttIcon,
            price: 1490.9,
          },
          {
            title: "FLN Hosting",
            paragraphs: [
              "Manutenção e hospedagem para seu site 24/7.",
              "Auxílio na compra do domínio.",
              "Hospedagem em plataforma segura e prática.",
              "Entrega em até 2 dias com o domínio.",
              "Esse pacote só pode ser adquirido juntamente com algum dos pacotes acima.",
            ],
            icon: CloudIcon,
            extra: "R$ 89,90/mês",
          },
        ],
      },
    ],
  },
  {
    name: "Dúvidas",
    title: "Dúvidas",
    backgroundColor: "#FFFFFF",
    textColor: "#d8ae9c",
    paragraphs: [
      "Aqui tiramos algumas dúvidas sobre os pacotes de soluções da FLN Tech.",
    ],
    subSection: [
      {
        name: "Como os prazos são tão rápidos?",
        title: "1. Como os prazos são tão rápidos?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "Parcerias. Utilizamos Inteligência Artifical de plataformas parceiras para agilizar o processo de criação de conteúdo e design, com a supervisão de um designer parceiro e um revisor parceiro.",
        ],
      },
      {
        name: "Quais são os modelos de site oferecidos?",
        title: "2. Quais são os modelos de site oferecidos?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "Contamos com 3 modelos de site hoje, criados por IA com a supervisão de um designer parceiro, são disponibilizados no primeiro contato com interesse nos pacotes FLN Essentials ou FLN Complete.",
        ],
      },
      {
        name: "Quem são os parceiros?",
        title: "3. Quem são os parceiros?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "Hoje contamos com um revisor e um designer, que estão à disposição para auxiliar no processo e entregar os pacotes com agilidade e qualidade.",
        ],
      },
      {
        name: "Existe possibilidade de alteração nos preços após a escolha dos pacotes?",
        title:
          "4. Existe possibilidade de alteração nos preços após a escolha dos pacotes?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "Não, os preços são a somátória dos pacotes escolhidos previamente apresentados aqui no site.",
        ],
      },
      {
        name: "Existe possibilidade de alteração no prazo de entrega?",
        title: "5. Existe possibilidade de alteração no prazo de entrega?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "Não, os prazos seguem os apresentados nos pacotes, existe a possibilidade de não disponilidade de iniciar instantaneamente, o que será conversado no primeiro contato.",
        ],
      },
      {
        name: "Porque não posso selecionar o FLN Hosting separadamente?",
        title: "6. Porque não posso selecionar o FLN Hosting separadamente?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "O FLN Hosting é um pacote exclusivo para sites que foram gerados pela FLN Tech, por isso não pode ser adquirido separadamente.",
        ],
      },
      {
        name: "O que é FLN Custom?",
        title: "7. O que é FLN Custom?",
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
        paragraphs: [
          "FLN Custom é o pacote destinado para soluções mais complexas, como aplicativos, sistemas, e-commerce, entre outros. Para isso, é necessário entrar em contato conosco para que possamos entender melhor o seu projeto e oferecer uma solução personalizada.",
        ],
      },
    ],
  },
];

export const addresses = [
  {
    name: "FLN Tech",
    address: "R. Esteves Júnior, 50 - Centro, Florianópolis - SC, 88015-130",
    addressSecondLine: "Edícifio Top Tower",
    contact: "(48) 99619-8272",
    contactRaw: "48996198272",
    website: "https://flntech.io",
  },
];

export const socialMedias = [
  {
    name: "phone",
    link: `tel:${cellphone}`,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/flntech.io/",
  },
  {
    name: "whatsapp",
    link: `https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`,
  },
];
