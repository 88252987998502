import { CheckOutlined } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
type TreatmnetCardProps = {
  title: string;
  paragraphs: string[];
  icon?: any;
  price?: number;
  extra?: string;
  onClick?: (title: string) => void;
  selected?: boolean;
};
export const SolutionsCard: React.FC<TreatmnetCardProps> = ({
  title,
  paragraphs,
  icon,
  price,
  extra,
  onClick,
  selected,
}) => (
  <Card
    variant="outlined"
    sx={{
      width: { lg: "25%" },
      marginRight: 2,
      backgroundColor: "white",
      marginBottom: 2,
      cursor: "pointer",
      border: selected ? "2px solid #363945" : "2px solid #E7ECEF",
    }}
    onClick={onClick ? () => onClick(title) : undefined}
  >
    <CardContent>
      <Icon
        component={icon}
        sx={{
          fontSize: 60,
          textAlign: "center",
          width: "100%",
          marginBottom: 2,
        }}
      ></Icon>
      <Typography
        variant="h5"
        sx={{ marginBottom: 2, textAlign: "center", fontWeight: 600 }}
      >
        {title}
      </Typography>
      <List sx={{ height: 250 }} dense>
        {paragraphs.map((paragraph) => (
          <ListItem sx={{ padding: "0 16px" }}>
            <ListItemIcon sx={{ color: "text.primary", minWidth: 40 }}>
              <CheckOutlined />
            </ListItemIcon>
            <ListItemText primary={paragraph} />
          </ListItem>
        ))}
      </List>
      {price && (
        <>
          <Divider />
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              color: "text.secondary",
              marginTop: 2,
              fontWeight: 600,
            }}
          >
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(price)}
          </Typography>
        </>
      )}
      {extra && (
        <>
          <Divider />
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              color: "text.secondary",
              marginTop: 2,
              fontWeight: 600,
            }}
          >
            {extra}
          </Typography>
        </>
      )}
    </CardContent>
  </Card>
);
