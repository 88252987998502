import { Box, IconButton, Link, Typography } from "@mui/material";
import { socialMedias } from "../../content";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import mainLogo from "../../content/images/fln-tech-high-resolution-logo-white-on-transparent-background.png";
import { addresses } from "../../content";

export default function FooterSection() {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        padding: { xs: 2, md: 4 },
        display: { md: "flex" },
      }}
    >
      <img src={mainLogo} className="App-logo" alt="logo" height="200px" />
      <Box
        sx={{
          color: "#ffffff",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          {addresses.map((address) => (
            <Box>
              <Typography variant="h6">{address.name}</Typography>
              <Typography variant="body1">{address.address}</Typography>
              <Typography variant="body1">
                <Link
                  sx={{ color: "#FFFFFF", cursor: "pointer" }}
                  href={`tel:${address.contact}`}
                >
                  {address.contact}
                </Link>
              </Typography>
            </Box>
          ))}
        </Box>

        <Typography
          sx={{ display: "flex", marginTop: 4, justifyContent: { md: "end" } }}
        >
          Entre em contato diretamente:
        </Typography>

        <Box sx={{ display: "flex", justifyContent: { md: "end" } }}>
          {socialMedias.map((socialMedia, index) => (
            <IconButton
              aria-label={`social-media-${index}`}
              sx={{ color: "#FFFFFF" }}
              onClick={() => window.open(socialMedia.link, "_blank")}
            >
              {socialMedia.name === "phone" && <PhoneIcon />}
              {socialMedia.name === "instagram" && <InstagramIcon />}
              {socialMedia.name === "whatsapp" && <WhatsAppIcon />}
            </IconButton>
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", marginTop: 4 }}>
          <Typography variant="caption">
            Criado e desenvolvido por FDR Tecnologia LTDA.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
