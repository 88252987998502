import { Box, Button, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { landingPageActionButtonNumber, pages } from "../../content";
import { ScheduleNowButton } from "../atoms/ScheduleNowButton";
import { SolutionsCard } from "../atoms/SolutionsCard";

export default function ContentSection() {
  const [selectedPackage, setSelectedPackage] = useState<string>("");
  const [extraService, setSelectedExtraService] = useState<string>("");

  const handlePackageCardClick = (title: string) => {
    if (selectedPackage === title) {
      setSelectedPackage("");
      return;
    }
    setSelectedPackage(title);
  };

  const handleExtraServiceCardClick = (title: string) => {
    if (extraService === title) {
      setSelectedExtraService("");
      return;
    }
    if (title === "FLN Hosting" && !selectedPackage) {
      return;
    }
    setSelectedExtraService(title);
  };

  const handleActionClick = () => {
    if (selectedPackage) {
      window.open(
        `https://wa.me/${landingPageActionButtonNumber}?text=Olá, gostaria de saber mais sobre o pacote ${selectedPackage} ${
          extraService ? `e o serviço extra ${extraService}` : ""
        }. Obrigado!`
      );
    }
  };

  return (
    <Box>
      {pages.map((page) => (
        <Box
          sx={{
            paddingX: { xs: 4, md: 16 },
            paddingY: 4,
            backgroundColor: page.backgroundColor,
          }}
        >
          <Divider
            sx={{
              width: 100,
              borderColor: "text.primary",
              marginBottom: 2,
            }}
          />
          <Typography variant="h3" sx={{ marginBottom: 4 }}>
            {page.title}
          </Typography>
          <Box
            sx={{
              display: { md: "flex" },
              alignItems: { md: "center" },
            }}
          >
            <Box
              sx={{
                width: { md: page.paragraphImage ? "50%" : "100%" },
              }}
            >
              {page.paragraphs?.length ? (
                <Box
                  sx={{
                    paddingRight: { md: 4 },
                  }}
                >
                  {page.paragraphs?.map((paragraph) => (
                    <Typography
                      sx={{
                        paddingBottom: 2,
                        textAlign: "justify",
                        color: "text.secondary",
                      }}
                    >
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              ) : null}
            </Box>

            {page.paragraphImage && (
              <Box
                sx={{
                  width: { md: "50%" },
                  height: "200px",
                }}
              >
                <img alt="paragraph" src={page.paragraphImage} height="180px" />
              </Box>
            )}
          </Box>

          {page.items?.length ? (
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ display: { lg: "flex" }, width: "100%" }}>
                {page.items?.map((item) => (
                  <SolutionsCard
                    title={item.title}
                    paragraphs={item.paragraphs}
                    icon={item.icon}
                    price={item.price}
                    extra={item.extra}
                    selected={selectedPackage === item.title}
                    onClick={handlePackageCardClick}
                  />
                ))}
              </Box>

              <Button
                variant="contained"
                sx={{ marginTop: 2 }}
                disabled={!selectedPackage && !extraService}
                onClick={handleActionClick}
              >
                Contrate já
              </Button>
            </Box>
          ) : null}
          {page.subSection?.length
            ? page.subSection?.map((subSection) => (
                <Box sx={{ width: "100%", marginBottom: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    {subSection.title}
                  </Typography>
                  {subSection.paragraphs.map((subSectionParagraph) => (
                    <Typography
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {subSectionParagraph}
                    </Typography>
                  ))}

                  {subSection.items?.length ? (
                    <Box sx={{ display: { lg: "flex" }, width: "100%" }}>
                      {subSection.items?.map((subSectionItem) => (
                        <SolutionsCard
                          title={subSectionItem.title}
                          paragraphs={subSectionItem.paragraphs}
                          icon={subSectionItem.icon}
                          price={subSectionItem.price}
                          extra={subSectionItem.extra}
                          selected={extraService === subSectionItem.title}
                          onClick={handleExtraServiceCardClick}
                        />
                      ))}
                    </Box>
                  ) : null}
                </Box>
              ))
            : null}

          {page.conclusion && (
            <Typography sx={{ marginBottom: 4, color: "text.secondary" }}>
              {" "}
              {page.conclusion}
            </Typography>
          )}
          {page.action && (
            <Box sx={{ textAlign: { xs: "center", md: "initial" } }}>
              <ScheduleNowButton buttonText={page.action} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
