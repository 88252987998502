import { Button } from "@mui/material";
import React from "react";
import {
  landingPageActionButtonMessage,
  landingPageActionButtonNumber,
} from "../../content";
type ScheduleNowButtonProps = {
  buttonText?: string;
};
export const ScheduleNowButton: React.FC<ScheduleNowButtonProps> = ({
  buttonText = "Quero meu site",
}) => (
  <Button
    variant="outlined"
    size="large"
    component="a"
    href={`https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`}
    sx={{
      minWidth: 200,
      color: "#FFFFFF",
      borderColor: "#FFFFFF",
      "&:hover": {
        borderColor: "#FFFFFF",
        bgcolor: "#FFFFFF",
        color: "#363945",
      },
    }}
    target="_blank"
  >
    {buttonText}
  </Button>
);
